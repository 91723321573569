<template>
    <main id="main">
        <section>
            <div
                style="padding: 0.5rem; border: 1px solid var(--cor-separador); z-index: 9998; background-color: var(--cor-bg);">
                <div class="tags" style="width: 100%; justify-content: center;">
                    <a title="Ano inteiro!" :class="this.mes == '' ? 'ativo' : ''"
                        style="cursor: pointer; margin-right: 0.2rem;"
                        @click="getPropostaComercialAno(), getTicketsAno(), getProdutosAno(), getClienteAno(), getClienteAno(), getProdutosAcabadosAno(), getPagarReceberAno()"><i
                            class="bi bi-calendar-minus botoes"></i></a>
                    <a v-for="i in nomeDosMeses" :key="i" style="cursor: pointer" :id="i.id"
                        :class="this.mes == i.id ? 'ativo' : ''"
                        @click="this.mes = i.id, igualameses(), getPropostaComercialMes(), getTicketsMes(), getProdutosMes(), getClienteMes(), getProdutosAcabadosMes(), getPagarReceberMes()">{{
                            i.nome }}</a>
                    <select v-model="ano" style="width: 6rem; border-radius: 25px;"
                        @change="getProdutosAno(), getPropostaComercialAno(), getTicketsAno(), getClienteAno(), getProdutosAcabadosAno(), getPagarReceberAno()">
                        <option style="color: var(--cor-fonte);">2023</option>
                        <option style="color: var(--cor-fonte);">2024</option>
                    </select>
                    <!-- <a><i class="bi bi-bar-chart-line-fill"></i></a>
                <a><i class="bi bi-graph-up"></i></a> -->
                </div>
            </div>
            <div style="padding: 1rem">
                <div style="display: flex;flex-flow: column; width: 100%; border: 1px solid var(--cor-separador); border-radius: 15px"
                    v-if="dadosUsuario && dadosUsuario.funcionalidade_id.includes(59)">
                    <div @click="mostrarClasse('Comercial', 'iconeComercial')" class="titulo bg-ok"
                        style="height: 3rem; border-radius: 15px; cursor: pointer; align-content: center; padding-left: 1rem;">
                        <strong style="font-size: 20px;"><i id="iconeComercial" style="margin-right: 0.5rem;"
                                class="bi bi-arrow-right"></i>Comercial</strong>
                    </div>
                    <div id="Comercial" style="display: none; margin-top: 1rem; padding: 0.5rem;">

                        <a href="#" class="ativo" id="toogle" @click="mostrarGrafico('canvaPropostaComercial')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Propostas
                            Comerciais</a>

                        <div id="canvaPropostaComercial">

                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 23rem; padding: 1rem">
                                    <div>
                                        <b>Fórmula:</b> Soma das oportunidades no status "conquistado" por mês. <br>
                                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                                        <b>Fonte:</b> OMIE > CRM > Oportunidades. <br>
                                        <b>Descrição:</b> Quantidade de oportunidades que foram conquistadas em
                                        determinado
                                        mês.<br>
                                    </div>
                                </v-card>
                            </v-menu>

                            <canvas id="ChartPropostaComercial"></canvas>
                        </div>

                        <a href="#" class="" id="toogle" @click="mostrarGrafico('ProdutosVendido')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Produtos
                            Vendidos</a>

                        <div style="margin-top: 1rem; display: none;" id="ProdutosVendido">

                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 23rem; padding: 1rem;">
                                    <div>
                                        <b>Fórmula:</b> Soma da quantidade de itens vendidos em um mês. <br>
                                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                                        <b>Fonte:</b> OMIE > Produtos > Pedido-Compra e Código de Etapa é igual a
                                        "faturado".
                                        <br>
                                        <b>Descrição:</b> Quantidade de produtos vendidos em um mês.<br>
                                    </div>
                                </v-card>
                            </v-menu>


                            <canvas id="ChartProdutos"></canvas>
                            <select v-model="familiaProdutos" @change="getProdutosAno()"
                                style="width: 10rem; margin: 0.2rem 0 0.5rem 1rem">
                                <option v-for=" p in listaProdutos" :key="p">{{ p }}</option>
                            </select>
                        </div>

                        <a href="#" class="" id="toogle" @click="mostrarGrafico('Clientes')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Clientes</a>

                        <div style="margin-top: 1rem; display: none;" id="Clientes">

                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 30rem; padding: 1rem;">
                                    <div>
                                        <b>Fórmula:</b> Soma das oportunidades quando o motivo é igual a "oportunidade
                                        conquistada" e o tipo corresponde a "Cliente novo", "Cliente reciclado" e
                                        "Cliente
                                        recorrente".<br>
                                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                                        <b>Fonte:</b> OMIE > Oportunidade quando o tipo é igual a Cliente novo, Cliente
                                        reciclado e Cliente recorrente. <br>
                                        <b>Descrição:</b> Quantidade de oportunidades conquistadas classificadas por
                                        tipo de
                                        Proposta Comercial.<br>

                                    </div>
                                </v-card>
                            </v-menu>

                            <canvas id="ChartClientes"></canvas>
                        </div>


                    </div>
                </div>


                <div v-if="dadosUsuario && dadosUsuario.funcionalidade_id.includes(58)"
                    style="display: flex;flex-flow: column; width: 100%; border: 1px solid var(--cor-separador); border-radius: 15px; margin-top: 0.5rem;">

                    <div @click="mostrarClasse('Financeiro', 'iconeFinanceiro')" class="titulo bg-sucesso"
                        style="height: 3rem; border-radius: 15px; cursor: pointer; align-content: center; padding-left: 1rem;">
                        <strong style="font-size: 20px;">
                            <i id="iconeFinanceiro" style="margin-right: 0.5rem;"
                                class="bi bi-arrow-right"></i>Financeiro</strong>
                    </div>

                    <div id="Financeiro" style="display: none; margin-top: 1rem; padding: 0.5rem;">

                        <a href="#" class="ativo" id="toogle" @click="mostrarGrafico('Projetos')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Custo de
                            projetos</a>

                        <div id="Projetos">
                            <!-- v-if="show == true" -->
                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 23rem; padding: 1rem;">
                                    <div>
                                        <b>Fórmula:</b> Somatório dos valores das requisições vinculadas a cada projeto.
                                        <br>
                                        <b>Polaridade:</b> Quanto menor, melhor. <br>
                                        <b>Fonte:</b> SGI <br>
                                        <b>Descrição:</b> Valores em R$ gastos em cada projeto registrado.<br>

                                    </div>
                                </v-card>
                            </v-menu>

                            <div
                                style="display: flex; justify-content: flex-end; align-items: center; height: 2rem; text-align: center; margin-right: 0.5rem">
                                <div
                                    style="border: 2px rgba(129, 199, 132, 1) solid ; border-radius: 10px; padding: 0.2em ">
                                    Mostrar valores abaixo de:<br>
                                    <money3 v-model="linhaDeCorte" v-bind="config"
                                        style="width: 8rem;border: none; border-bottom: 1px black solid; outline: none;background-color: transparent;"
                                        @keyup.enter="definirListaDeProjetos"></money3>
                                </div>
                            </div>

                            <canvas id="chartProjetos"></canvas>
                        </div>

                        <a href="#" class="" id="toogle" @click="mostrarGrafico('PagosRecebidos')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Pagos e
                            Recebidos</a>

                        <div id="PagosRecebidos" style="display: none;">

                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 40rem; padding: 1rem;">
                                    <div>
                                        <b>Fórmula:</b> Somatório dos valores dos processos pagos com os status
                                        (Atrasado e
                                        Pago), somatório dos valores recebidos com os status (Atrasado, Cancelado e
                                        Recebido).<br>
                                        <b>Polaridade:</b> Saldo: Quanto maior, melhor.<br>
                                        <b>Fonte:</b> OMIE > Finanças > Contas a Pagar/Receber.<br>
                                        <b>Descrição:</b> Barra verde: Demonstração do total de contas recebidas no
                                        período
                                        selecionado.<br>
                                        Barra vermelha: Demonstração do total de contas pagas no período
                                        selecionado.<br>
                                        Linha amarela: Demonstração do saldo (valor a receber - valor a pagar) no
                                        período
                                        selecionado.

                                    </div>
                                </v-card>
                            </v-menu>

                            <canvas style="margin-top: 1rem;" id="ChartPagarReceber"></canvas>
                        </div>
                    </div>
                </div>

                <div v-if="dadosUsuario && dadosUsuario.funcionalidade_id.includes(60)"
                    style="display: flex;flex-flow: column; width: 100%; border: 1px solid var(--cor-separador); border-radius: 15px; margin-top: 0.5rem;">


                    <div @click="mostrarClasse('Produção', 'iconeProdução')"
                        style="height: 3rem; border-radius: 15px; cursor: pointer; align-content: center; padding-left: 1rem;"
                        class="titulo bg-erro">
                        <strong style="font-size: 20px;">
                            <i id="iconeProdução" style="margin-right: 0.5rem;" class="bi bi-arrow-right"></i>Produção
                        </strong>
                    </div>

                    <div id="Produção" style="display: none; margin-top: 1rem; padding: 0.5rem;">

                        <a href="#" class="ativo" id="toogle" @click="mostrarGrafico('ProdutosAcabados')"
                            onclick="this.classList.toggle('ativo');return false"><span
                                class="toggle"></span>&nbsp;Produção
                            Diária</a>


                        <div id="ProdutosAcabados">

                            <v-menu location="end">
                                <!-- :close-on-content-click="false" -->
                                <template v-slot:activator="{ props }">
                                    <i style="position: absolute; margin-left: 1rem; cursor: pointer;" v-bind="props"
                                        class="bi bi-info-circle"></i>
                                </template>

                                <v-card min-width="100" style="max-width: 23rem; padding: 1rem;">
                                    <div>
                                        <b>Fórmula:</b> Quantidade de produtos acabados (excluindo o tipo 'conjunto') e
                                        etapa = disponível / 22. <br>
                                        <b>Polaridade:</b> Quanto maior, melhor. <br>
                                        <b>Fonte:</b> OMIE > Produção > Produto acabado. <br>
                                        <b>Descrição:</b> Quantidade diária de produtos acabados, excluindo o tipo
                                        'conjunto'.<br>

                                    </div>
                                </v-card>
                            </v-menu>

                            <canvas id="ChartProdutosAcabados"></canvas>

                            <select v-model="produto" @change="getProdutosAcabadosAno()"
                                style="width: 10rem; margin: 0.2rem 0 0.5rem 1rem; border-radius: 10px;">
                                <option v-for=" p in listaProdutos" :key="p">{{ p }}</option>
                            </select>
                        </div>

                    </div>
                </div>

                <!-- ------------------- MODAIS --------------------------- -->
                <div style="overflow: auto" class="modal-mask" v-if="modalDetalhePagoeRecebido"
                    @click="fecharModalFora">
                    <div style="max-width: 60vw" class="modal-container">

                        <div style="text-align: center;">
                            <h1>
                                {{ labelsPagarReceber[dataXPagosRecebidos] }} de {{ nomesDosMesessemid[mesPagarReceber -
                                    1]
                                }}
                            </h1>
                        </div>


                        <div style="position: absolute; right: 1rem;">

                            <input v-model="PagoOuRecebidoModal" type="radio" class="btn-check" name="options-base"
                                id="option5" autocomplete="off" checked value="pago">
                            <label class="btn" for="option5" style="color: var(--cor-fonte)">Pagos</label>

                            <input v-model="PagoOuRecebidoModal" type="radio" class="btn-check" name="options-base"
                                id="option6" autocomplete="off" value="recebido">
                            <label class="btn" for="option6"
                                style="margin-right: 0.5rem; color: var(--cor-fonte);">Recebidos</label>

                            <input checked @change="classificarStatus('decrescente')" type="radio"
                                class="btn-check botoes" name="ordenação" id="decrescente" value="decrescente"
                                autocomplete="off">
                            <label style="color: var(--cor-fonte);font-size: 25px;" class="btn botoes"
                                for="decrescente"><i class="bi bi-sort-numeric-down-alt"></i></label>

                            <input @change="classificarStatus('crescente')" type="radio" class="btn-check botoes"
                                name="ordenação" id="crescente" value="crescente" v-model="ordenacaoModal"
                                autocomplete="off">
                            <label style="color: var(--cor-fonte);margin-left: 0.5rem; font-size: 25px;"
                                class="btn botoes" for="crescente"><i class="bi bi-sort-numeric-up-alt"></i></label>
                        </div>

                        <div v-for="i in listaPagarReceber" :key="i">
                            <h5>
                                <b>Total Recebido:</b> {{ real(parseInt(i.valorSemanaReceber)) }} <br>
                                <b>Total Pago:</b> {{ real(parseInt(i.valorSemanaPagar)) }} <br>
                                <b>Saldo da semana:</b> {{ real(parseInt(i.valorSemanaReceber - i.valorSemanaPagar)) }}
                            </h5>

                            <div style="display: flex; align-items: baseline; justify-content: flex-end;">

                            </div>

                            <hr>

                            <div v-if="PagoOuRecebidoModal == 'pago'">
                                <div v-for="item in i.statusPagar" :key="item.statusPagar" style="margin-top: 1rem;">
                                    <b>Nome:</b> {{ item.nome }}<br>
                                    <b>Valor:</b> {{ real(parseInt(item.valor)) }}<br>
                                    <hr>
                                </div>
                            </div>

                            <div v-if="PagoOuRecebidoModal == 'recebido'">
                                <div v-for="item in i.statusReceber" :key="item.statusReceber"
                                    style="margin-top: 1rem;">
                                    <b>Nome:</b> {{ item.nome }}<br>
                                    <b>Valor:</b> {{ real(parseInt(item.valor)) }}<br>
                                    <hr>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div style="overflow: auto" class="modal-mask" v-if="showModal" @click="fecharModalFora">
                    <div style="max-width: 60vw" class="modal-container">
                        <h1 style="text-align: center;">Propostas Viabilizadas:
                            <hr>
                        </h1>
                        <ul>
                            <li style="margin-top: 0.5rem;margin-right: 1rem;" v-for="c in PropostaComercial" :key="c">
                                {{ c }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="modal-mask" v-if="modalDetalheProjeto" @click="fecharModalFora">
                    <div class="modal-container" style="width: 60vw;">
                        <div>
                            <div style="text-align: center;">
                                <h1>
                                    {{ nomeModal }} - {{ detalheProjeto[0].Projeto }}
                                </h1>
                                Valor total do projeto: {{ real(parseInt(detalheProjeto[0].ValorTotalProjeto)) }}
                            </div>
                            <br>

                            <div style="position: absolute; margin-left: 41rem; margin-top: 4rem;">
                                <input
                                    @change="this.detalheProjeto = this.detalheProjeto.sort((a, b) => parseFloat(b.ValorTotal) - parseFloat(a.ValorTotal))"
                                    type="radio" class="btn-check botoes" name="ordenação" id="decrescente"
                                    value="decrescente" v-model="ordenacaoModal" autocomplete="off">
                                <label style="color: rgb(0, 0, 0);font-size: 25px;" class="btn botoes"
                                    for="decrescente"><i class="bi bi-sort-numeric-down-alt"></i></label>

                                <input
                                    @change="this.detalheProjeto = this.detalheProjeto.sort((a, b) => parseFloat(a.ValorTotal) - parseFloat(b.ValorTotal))"
                                    type="radio" class="btn-check botoes" name="ordenação" id="crescente"
                                    value="crescente" v-model="ordenacaoModal" autocomplete="off">
                                <label style="color: rgb(0, 0, 0);margin-left: 0.5rem; font-size: 25px;"
                                    class="btn botoes" for="crescente"><i class="bi bi-sort-numeric-up-alt"></i></label>
                            </div>

                            <div v-for="i in detalheProjeto" :key="i">
                                <hr>
                                <div>
                                    <h2>Requisição: {{ i.Requisição }}<br>Valor total: {{ real(parseInt(i.ValorTotal))
                                        }}
                                    </h2>

                                    <b>Solicitante:</b> {{ i.Solicitante }} <br>
                                    <b>Aprovação:</b> {{ i.Aprovação }} <br>
                                    <b>Data:</b> {{ extrairHoraDeString(i.Data) }} <br>
                                    <b>Fornecedor:</b> {{ i.Fornecedor }}<br><br>
                                    <h5><b>Item(s):</b></h5>
                                    <div v-for="item in i.itens" :key="item.Nome">
                                        <b>Nome:</b> {{ item.Nome }}<br>
                                        <b>Valor:</b> {{ real(parseInt(item.Valor)) }}<br>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { Money3Component } from 'v-money3';
import { api } from "roboflex-thalamus-request-handler";
import { urlFoto } from "../services/api";
import { sso } from "roboflex-thalamus-sso-lib"

export default {
    setup() {

        return { urlFoto };

    },

    components: { money3: Money3Component },

    data() {
        return {
            dadosUsuario: null,

            PagoOuRecebidoModal: "pago",
            teste: 'teste',
            ordenacaoModal: "decrescente",
            nomeModal: "",
            linhaDeCorte: 1500000,
            modalDetalheProjeto: false,
            modalDetalhePagoeRecebido: false,
            detalhePagoeRecebido: [],
            detalheProjeto: [],
            tipodegrafico: "bar",
            listaProdutos: [],
            produto: "Todos",
            familiaProdutos: "Todos",
            dadosFormatadosC: [],
            dadosFormatadosT: [],
            dadosFormatadosP: [],
            showModal: false,
            PropostaComercial: "",
            mesSemana: "",
            semana: "",
            data: "",
            dataX: "",
            mes: "",
            mesPropostasViabilizadas: "",
            mesTickets: "",
            mesProdutos: "",
            ano: "2024",
            dados: '',
            dadostickets: '',
            dadosProdutos: '',
            nome: null,
            nomeDosMeses: [{ "id": 1, "nome": 'Janeiro' },
            { "id": 2, "nome": 'Fevereiro' },
            { "id": 3, "nome": 'Março' },
            { "id": 4, "nome": 'Abril' },
            { "id": 5, "nome": 'Maio' },
            { "id": 6, "nome": 'Junho' },
            { "id": 7, "nome": 'Julho' },
            { "id": 8, "nome": 'Agosto' },
            { "id": 9, "nome": 'Setembro' },
            { "id": 10, "nome": 'Outubro' },
            { "id": 11, "nome": 'Novembro' },
            { "id": 12, "nome": 'Dezembro' }],
            nomesDosMesessemid: [
                "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
            ],
            dataGrafico: {},
            dataGraficoTickets: {},
            dataGraficoProdutos: {},
            labels: [],
            labelsTickets: [],
            labelsProdutos: [],
            datasets: [],
            datasetsTickets: [],
            datasetsProdutos: [],

            mesCliente: "",
            dadosClientes: [],
            dadosFormatadosClientes: [],
            labelsclientes: [],
            dataGraficoClientesNovos: [],
            dataGraficoClientesRecorrente: [],
            dataGraficoClientesReciclado: [],
            datasetsClientes: [],

            mesProdutosAcabados: "",
            dadosProdutosAcabados: [],
            dadosFormatadosProdutosAcabados: [],
            labelsProdutosAcabados: [],
            datasetsProdutosAcabados: [],
            dataGraficoProdutosAcabadosTotal: [],
            dataGraficoProdutosAcabadosMediaDia: [],

            listaDeProjetos: [],
            custoDeProjetos: [],
            nomesDosProjetos: [],

            mesPagarReceber: "",
            dadosPagarReceber: [],
            dadosFormatadosPagarReceber: [],
            labelsPagarReceber: [],
            dataGraficoPagar: [],
            dataGraficoReceber: [],
            datasetsPagarReceber: [],
            semanaPagoRecebidos: [],
            listaPagarReceber: [],
            dataXPagosRecebidos: 0,

            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            }
        }
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        api.get(`usuario/${usuarioLogado.id}/funcionalidades`)
            .then((response) => {
                this.dadosUsuario = response.data
            });
    },

    mounted() {
        this.definirListaProdutos();
        this.getPropostaComercialAno();
        this.getProdutosAno();
        this.getClienteAno();
        this.getProdutosAcabadosAno();
        this.definirListaDeProjetos();
        this.getPagarReceberAno();
    },

    methods: {
        classificarStatus(ordem) {
            if (ordem === "decrescente") {
                this.listaPagarReceber.forEach(semana => {
                    // Ordenar os itens em 'statusPagar' com base no valor
                    semana.statusPagar.sort((a, b) => b.valor - a.valor);
                });
                this.listaPagarReceber.forEach(semana => {
                    // Ordenar os itens em 'statusPagar' com base no valor
                    semana.statusReceber.sort((a, b) => b.valor - a.valor);
                });
            } else {
                this.listaPagarReceber.forEach(semana => {
                    // Ordenar os itens em 'statusPagar' com base no valor
                    semana.statusPagar.sort((a, b) => a.valor - b.valor);
                });
                this.listaPagarReceber.forEach(semana => {
                    // Ordenar os itens em 'statusPagar' com base no valor
                    semana.statusReceber.sort((a, b) => a.valor - b.valor);
                });
            }
        },

        extrairHoraDeString(dataString) {
            const data = new Date(dataString);
            const dia = (data.getDate() + 1);
            const mes = (data.getMonth() + 1);
            const ano = (data.getFullYear());
            return `${dia}/${mes}/${ano}`;
        },

        real(num) {
            return num.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },

        definirListaProdutos() {
            api.get(`/buscar/familia-produto-vendido`)
                .then(response => {
                    this.listaProdutos = response.data
                    this.listaProdutos = this.listaProdutos.map((item) => item.familia_nome.charAt(0).toUpperCase() + item.familia_nome.slice(1).toLowerCase());
                    this.listaProdutos.push("Todos")
                })
                .catch(error => {
                    console.error(error);
                });

        },

        mostrarGrafico(id) {
            var element = document.getElementById(id);
            if (element.style.display == "none") {
                element.style.display = ""
            } else {
                element.style.display = "none";
            }
        },

        // FUNÇÃO PARA OCULTAR/MOSTRAR E MODIFICAR O ICONE DA DIV EM QUE ESTÁ O GRAFICO
        mostrarClasse(id, icone) {
            var setor = document.getElementById(id)
            var icon = document.getElementById(icone)
            if (setor.style.display != "none") {
                setor.style.display = "none";
                icon.className = "bi bi-arrow-right"
            } else {
                setor.style.display = "grid"
                icon.className = "bi bi-arrow-return-right"
            }
        },

        abrirModal() {
            this.PropostaComercial = "",
                api.post('omie/oportunidade/proposta-viabilizada/detalhe', {
                    anoSemana: this.ano + this.semana,
                })
                    .then((response) => {
                        this.PropostaComercial = response.data;
                        this.PropostaComercial = this.PropostaComercial.map((item) => item.cDesOp.slice(1).slice(0, -1));
                        console.log(response);
                        console.log(this.PropostaComercial);
                    })

                    .catch((error) => {
                        console.error(error);
                    });
            this.showModal = !this.showModal;
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.showModal = false;
                this.modalDetalheProjeto = false
                this.modalDetalhePagoeRecebido = false
            }
        },

        // OS DADOS TEMPORAIS VEM DO END POINT EM FORMATO DE SEMANA DO ANO, ESTÁ FUNÇÃO DIZ A QUAL MES A SEMANA CORRESPONDE
        calcularMesDaSemana() {
            const data = new Date(this.ano, 0, 1);
            data.setDate(data.getDate() + (this.semana - 1) * 7);
            this.mesSemana = data.getMonth() + 1;
        },

        // IGUALA OS MESES PARA QUANDO O BOTÃO DO MES QUE ESTÁ NO CABEÇALHO SEJA CLICADO TODOS OS GRAFICOS MOSTREM O MES SELECIONADO
        igualameses() {
            this.mesTickets = this.mes;
            this.mesProdutos = this.mes;
            this.mesCliente = this.mes;
            this.mesProdutosAcabados = this.mes;
            this.mesPropostasViabilizadas = this.mes;
            this.mesPagarReceber = this.mes;
        },

        // GERA UM GRÁFICO
        getPropostaComercialMes() {
            // PUXA OS DADOS DO BACKEND PASSANDO MES E ANO
            this.mes = this.mesPropostasViabilizadas
            api.post('/omie/oportunidade/proposta-viabilizada', {
                mes: this.mesPropostasViabilizadas,
                ano: this.ano,
            })
                .then((response) => {
                    this.dados = response.data;
                    this.dados.forEach((item) => {
                        item.semana = item.semana.toString().substring(4);
                    });

                    this.dadosFormatadosC = this.dados.map((item) => parseInt(item.semana, 10));

                    this.labels = this.dadosFormatadosC.map((value, index) => {
                        return `${index + 1}º semana`;
                    });

                    this.dataGrafico = this.dados.map((item) => item.regSemana);
                    this.datasets = [];
                    this.datasets.push({
                        type: 'line',
                        label: 'Meta',
                        backgroundColor: 'black',
                        borderColor: 'black',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [10, 10, 10, 10, 10]
                    }, {
                        data: this.dataGrafico,
                        type: this.tipodegrafico,
                        label: 'Propostas Comerciais Viabilizadas',
                        backgroundColor: '#3571CD',
                        borderColor: '#3571CD',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })

                    this.renderChartPropostaComercial();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getPropostaComercialAno() {
            this.mes = ""
            this.mesPropostasViabilizadas = ""
            api.post('/omie/oportunidade/proposta-viabilizada-mes', {
                ano: this.ano,
            })
                .then((response) => {
                    this.dados = response.data;

                    this.dadosFormatadosC = this.dados.map((item) => item.mes);
                    // DEFINE AS VARIAVEIS NECESSARIAS PARA CONSTRUIR O GRÁFICO: LABELS, DATASETS
                    this.labels = this.dados.map((item) => item.mes);
                    this.labels = this.labels.map((numero) => this.nomesDosMesessemid[numero - 1])

                    this.dataGrafico = this.dados.map((item) => item.valor);
                    this.datasets = [];
                    this.datasets.push(
                        {
                            data: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30],
                            type: 'line',
                            label: 'Meta',
                            backgroundColor: 'rgba(0, 0, 0, 1)',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 0,
                            pointHoverRadius: 0,
                        },
                        {
                            data: this.dataGrafico,
                            type: this.tipodegrafico,
                            label: 'Propostas Comerciais Viabilizadas',
                            backgroundColor: '#3571CD',
                            borderColor: '#3571C',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                        })
                    this.renderChartPropostaComercial()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartPropostaComercial() {
            const canvas = document.getElementById('ChartPropostaComercial');
            const ctx = canvas.getContext('2d');
            // LIMPA O CANVA CASO ELE ESTEJA PREENCHIDO (PREVENÇÃO DE ERRO)

            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labels,
                    datasets: this.datasets,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: ''
                                }
                            }
                        }
                    },
                    // FUNÇÃO ENTRA EM AÇÃO AO CLICAR EM ALGUM DADO DO GRÁFICO
                    onClick: (e) => {
                        if (this.mesPropostasViabilizadas == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.mesPropostasViabilizadas = this.dadosFormatadosC[dataX]
                            this.getPropostaComercialMes();
                        } else {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.semana = "";
                            this.semana = this.dados[dataX];
                            this.semana = this.semana.semana

                            this.abrirModal()
                        }
                    }
                },
            });
        },

        getTicketsMes() {
            this.mes = this.mesTickets;
            api.post('/omie/oportunidade/ticket', {
                mes: this.mesTickets,
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosTickets = response.data;
                    this.dadosTickets.forEach((item, index) => {
                        item.semana = index + 1 + "º Semana";
                    });

                    this.labelsTickets = this.dadosTickets.map((item) => item.semana);
                    this.dataGraficoTickets = this.dadosTickets.map((item) => item.regSemana);
                    this.datasetsTickets = [];
                    this.datasetsTickets.push({
                        type: 'line',
                        label: 'Meta',
                        backgroundColor: 'black',
                        borderColor: 'black',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [1000000, 1000000, 1000000, 1000000, 1000000]
                    }, {
                        data: this.dataGraficoTickets,
                        type: this.tipodegrafico,
                        label: 'Tickets',
                        backgroundColor: 'rgba(129, 199, 132, 1)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })

                    this.renderChartTickets();
                    this.semana = "";
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getTicketsAno() {
            this.mesTickets = ""
            api.post('/omie/oportunidade/ticket-mes', {
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosTickets = response.data;

                    this.dadosFormatadosT = this.dadosTickets.map((item) => item.mes)
                    this.labelsTickets = this.dadosFormatadosT
                    this.labelsTickets = this.labelsTickets.map((numero) => this.nomesDosMesessemid[numero - 1])

                    this.dataGraficoTickets = this.dadosTickets.map((item) => item.regMes);
                    this.datasetsTickets = [];
                    this.datasetsTickets.push({
                        type: 'line',
                        label: 'Meta',
                        backgroundColor: 'black',
                        borderColor: 'black',
                        pointRadius: 0,
                        borderWidth: 1,
                        data: [1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000]
                    }, {
                        data: this.dataGraficoTickets,
                        type: this.tipodegrafico,
                        label: 'Tickets',
                        backgroundColor: 'rgba(129, 199, 132, 1)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })
                    this.renderChartTickets()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartTickets() {
            const canvas = document.getElementById('ChartTickets');
            const ctx = canvas.getContext('2d');

            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labelsTickets,
                    datasets: this.datasetsTickets,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                }
                            }
                        }
                    },
                    onClick: (e) => {
                        if (this.mesTickets == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.mesTickets = this.dadosFormatadosT[dataX]
                            this.getTicketsMes();
                        }
                    }

                },
            });
        },

        getProdutosMes() {
            this.mes = this.mesProdutos
            api.post('/indicador/produto-vendido', {
                nome: this.familiaProdutos,
                ano: this.ano,
                mes: this.mesProdutos
            })
                .then((response) => {
                    this.dadosProdutos = response.data;
                    this.dadosProdutos.forEach((item, index) => {
                        item.semana = index + 1 + "º Semana";
                    });
                    this.labelsProdutos = this.dadosProdutos.map((item) => item.semana);
                    this.dataGraficoProdutos = this.dadosProdutos.map((item) => item.regSemana);
                    this.datasetsProdutos = [];
                    this.datasetsProdutos.push({
                        data: this.dataGraficoProdutos,
                        type: this.tipodegrafico,
                        label: 'Quantidade de Produtos Vendidos',
                        backgroundColor: '#db6363',
                        borderColor: '#db6363',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })

                    this.renderChartProdutos();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getProdutosAno() {
            this.mesProdutos = ""
            api.post('/indicador/produto-vendido-mes', {
                nome: this.familiaProdutos,
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosProdutos = response.data
                    this.dadosFormatadosP = this.dadosProdutos.map((item) => item.mes)
                    this.labelsProdutos = this.dadosFormatadosP
                    this.labelsProdutos = this.labelsProdutos.map((numero) => this.nomesDosMesessemid[numero - 1])
                    this.dataGraficoProdutos = this.dadosProdutos.map((item) => item.regMes);
                    this.datasetsProdutos = [];
                    this.datasetsProdutos.push({
                        data: this.dataGraficoProdutos,
                        type: this.tipodegrafico,
                        label: 'Quantidade de Produtos Vendidos',
                        backgroundColor: '#db6363',
                        borderColor: '#db6363',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })
                    this.renderChartProdutos()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartProdutos() {

            const canvas = document.getElementById('ChartProdutos');
            const ctx = canvas.getContext('2d');

            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labelsProdutos,
                    datasets: this.datasetsProdutos,
                },
                options: {
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                }
                            }
                        }
                    },
                    onClick: (e) => {
                        if (this.mesProdutos == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.mesProdutos = this.dadosFormatadosP[dataX]
                            this.getProdutosMes();
                        }
                    }

                },
            });
        },

        getClienteMes() {
            // PUXA OS DADOS DO BACKEND PASSANDO MES E ANO
            this.mes = this.mesCliente
            api.post('/omie/oportunidade/cliente-alcancado', {
                mes: this.mesCliente,
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosClientes = response.data;
                    this.dadosClientes.forEach((item) => {
                        item.semana = item.semana.toString().substring(4);
                    });

                    this.dadosFormatadosClientes = this.dadosClientes.map((item) => parseInt(item.semana, 10));

                    this.labelsclientes = this.dadosFormatadosClientes.map((value, index) => {
                        return `${index + 1}º semana`;
                    });

                    this.dataGraficoClientesNovos = this.dadosClientes.map((item) => item.cliente_novo);
                    this.dataGraficoClientesRecorrente = this.dadosClientes.map((item) => item.cliente_recorrente);
                    this.dataGraficoClientesReciclado = this.dadosClientes.map((item) => item.cliente_reciclado);

                    this.datasetsClientes = [];
                    this.datasetsClientes.push(
                        {
                            data: this.dataGraficoClientesNovos,
                            type: this.tipodegrafico,
                            label: 'Clientes Novos',
                            backgroundColor: '#3571cd',
                            borderColor: '#3571cd',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                        }, {
                        data: this.dataGraficoClientesRecorrente,
                        type: this.tipodegrafico,
                        label: 'Clientes Recorrentes',
                        backgroundColor: '#1d467b',
                        borderColor: '#1d467b',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    },
                        {
                            data: this.dataGraficoClientesReciclado,
                            type: this.tipodegrafico,
                            label: 'Clientes Reciclados',
                            backgroundColor: '#00b4fc',
                            borderColor: '#00b4fc',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                        })

                    this.renderChartClientes();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getClienteAno() {
            this.mesCliente = ""
            axios.post('/omie/oportunidade/cliente-alcancado', {
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosClientes = response.data;

                    this.dadosFormatadosClientes = this.dadosClientes.map((item) => item.mes);
                    this.labelsclientes = this.dadosClientes.map((item) => item.mes);
                    this.labelsclientes = this.labelsclientes.map((numero) => this.nomesDosMesessemid[numero - 1])

                    this.dataGraficoClientesNovos = this.dadosClientes.map((item) => item.cliente_novo);
                    this.dataGraficoClientesRecorrente = this.dadosClientes.map((item) => item.cliente_recorrente);
                    this.dataGraficoClientesReciclado = this.dadosClientes.map((item) => item.cliente_reciclado);
                    this.datasetsClientes = [];
                    this.datasetsClientes.push(
                        {
                            data: this.dataGraficoClientesNovos,
                            type: this.tipodegrafico,
                            label: 'Clientes Novos',
                            backgroundColor: '#3571CD',
                            borderColor: '#3571CD',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                        }, {
                        data: this.dataGraficoClientesRecorrente,
                        type: this.tipodegrafico,
                        label: 'Clientes Recorrentes',
                        backgroundColor: '#1d467b',
                        borderColor: '#1d467b',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    },
                        {
                            data: this.dataGraficoClientesReciclado,
                            type: this.tipodegrafico,
                            label: 'Clientes Reciclados',
                            backgroundColor: '#00b4fc',
                            borderColor: '#00b4fc',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                        })
                    this.renderChartClientes()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartClientes() {
            // DEFINE O CANVA QUE O GRÁFICO SERÁ GERADO
            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }


            const canvas = document.getElementById('ChartClientes');
            const ctx = canvas.getContext('2d');
            const footer = (tooltipItems) => {
                let sum = 0;

                tooltipItems.forEach(function (tooltipItem) {
                    sum += tooltipItem.parsed.y;
                });
                return 'Total: ' + sum + " Clientes";
            };
            // LIMPA O CANVA CASO ELE ESTEJA PREENCHIDO (PREVENÇÃO DE ERRO)
            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labelsclientes,
                    datasets: this.datasetsClientes,
                },
                options: {
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },

                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                footer: footer,
                            }
                        },
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                }
                            }
                        },
                    },
                    onClick: (e) => {
                        if (this.mesCliente == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.mesCliente = this.dadosFormatadosC[dataX]
                            this.getClienteMes();
                        }

                    }
                },
            });
        },

        getProdutosAcabadosMes() {
            this.mes = this.mesProdutosAcabados
            api.post('/indicador/produto-produzido', {
                mes: this.mesProdutosAcabados,
                ano: this.ano,
                produto: this.produto
            })
                .then((response) => {
                    this.dadosProdutosAcabados = response.data;
                    this.dadosProdutosAcabados.forEach((item) => {
                        item.semana = item.semana.toString().substring(4);
                    });

                    this.dadosFormatadosProdutosAcabados = this.dadosProdutosAcabados.map((item) => parseInt(item.semana, 10));

                    this.labelsProdutosAcabados = this.dadosFormatadosProdutosAcabados.map((value, index) => {
                        return `${index + 1}º semana`;
                    });

                    this.dataGraficoProdutosAcabadosTotal = this.dadosProdutosAcabados.map((item) => item.total);
                    this.dataGraficoProdutosAcabadosMediaDia = this.dadosProdutosAcabados.map((item) => item.mediaDia.slice(0, 5));

                    this.datasetsProdutosAcabados = [];
                    this.datasetsProdutosAcabados.push(
                        {
                            data: this.dataGraficoProdutosAcabadosTotal,
                            type: this.tipodegrafico,
                            label: 'Total',
                            backgroundColor: '#d50000',
                            borderColor: '#d50000',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                            hidden: true
                        }, {
                        data: this.dataGraficoProdutosAcabadosMediaDia,
                        type: this.tipodegrafico,
                        label: 'Produção Diária',
                        backgroundColor: '#fc4b2a',
                        borderColor: '#fc4b2a',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })
                    this.renderChartProdutosAcabados()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getProdutosAcabadosAno() {
            this.mesProdutosAcabados = ""
            api.post('/indicador/produto-produzido', {
                ano: this.ano,
                produto: this.produto
            })
                .then((response) => {
                    this.dadosProdutosAcabados = response.data;

                    this.dadosFormatadosProdutosAcabados = this.dadosProdutosAcabados.map((item) => item.mes);
                    this.labelsProdutosAcabados = this.dadosProdutosAcabados.map((item) => item.mes);
                    this.labelsProdutosAcabados = this.labelsProdutosAcabados.map((numero) => this.nomesDosMesessemid[numero - 1])

                    this.dataGraficoProdutosAcabadosTotal = this.dadosProdutosAcabados.map((item) => item.total);
                    this.dataGraficoProdutosAcabadosMediaDia = this.dadosProdutosAcabados.map((item) => item.mediaDia.slice(0, 5));

                    this.datasetsProdutosAcabados = [];
                    this.datasetsProdutosAcabados.push(
                        {
                            data: this.dataGraficoProdutosAcabadosTotal,
                            type: this.tipodegrafico,
                            label: 'Total',
                            backgroundColor: '#d50000',
                            borderColor: '#d50000',
                            borderWidth: 1.5,
                            tension: 0.3,
                            pointRadius: 2.2,
                            pointHoverRadius: 5,
                            hidden: true
                        }, {
                        data: this.dataGraficoProdutosAcabadosMediaDia,
                        type: this.tipodegrafico,
                        label: 'Produção Diária',
                        backgroundColor: '#fc4b2a',
                        borderColor: '#fc4b2a',
                        borderWidth: 1.5,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })
                    this.renderChartProdutosAcabados()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartProdutosAcabados() {
            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }
            // DEFINE O CANVA QUE O GRÁFICO SERÁ GERADO
            const canvas = document.getElementById('ChartProdutosAcabados');
            const ctx = canvas.getContext('2d');
            // LIMPA O CANVA CASO ELE ESTEJA PREENCHIDO (PREVENÇÃO DE ERRO)
            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labelsProdutosAcabados,
                    datasets: this.datasetsProdutosAcabados,
                },
                options: {
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },

                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                }
                            }
                        },
                    },
                    onClick: (e) => {
                        if (this.mesProdutosAcabados == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);

                            this.mesProdutosAcabados = this.dadosFormatadosProdutosAcabados[dataX]
                            this.getProdutosAcabadosMes();
                        }

                    }
                },
            });
        },

        definirListaDeProjetos() {
            this.linhaDeCorte = parseInt(this.linhaDeCorte);
            this.listaDeProjetos = [],
                this.nomesDosProjetos = [],
                this.custoDeProjetos = [],
                api.get('/sgi/projeto/lista', {
                })
                    .then((response) => {
                        let dadosProjetos = response.data.filter((item) => item.ValorTotal < this.linhaDeCorte);


                        this.nomesDosProjetos = dadosProjetos.map((item) => item.Nome);
                        this.custoDeProjetos = dadosProjetos.map((item) => item.ValorTotal);
                        this.listaDeProjetos = dadosProjetos.map((item) => item.Projeto);
                        this.renderChartProjetos();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
        },

        detalhesDeProjeto(num) {
            api.post('/indicador/projeto/custo', {
                codProjeto: num,
            })
                .then((response) => {
                    //const decrescente = (a, b) => parseFloat(b.ValorTotal) - parseFloat(a.ValorTotal);
                    //const crescente  = (a, b) => parseFloat(a.ValorTotal) - parseFloat(b.ValorTotal);

                    this.detalheProjeto = response.data;
                    this.detalheProjeto = this.detalheProjeto.sort((a, b) => parseFloat(b.ValorTotal) - parseFloat(a.ValorTotal));
                    this.modalDetalheProjeto = !this.modalDetalheProjeto
                })

                .catch((error) => {
                    console.error(error);
                });
        },

        renderChartProjetos() {
            const canvas = document.getElementById('chartProjetos');
            const ctx = canvas.getContext('2d');
            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }

            var linha = [];
            var quantidadeRepeticoes = this.listaDeProjetos.length;

            for (var i = 0; i < quantidadeRepeticoes; i++) {
                linha.push(this.linhaDeCorte);
            }


            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.listaDeProjetos,
                    datasets: [{
                        data: this.custoDeProjetos,
                        type: this.tipodegrafico,
                        label: 'Custo do projeto',
                        backgroundColor: 'rgba(129, 199, 132, 1)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 1.5,
                        tension: 0.3,
                    },
                    {
                        data: linha,
                        type: "line",
                        label: "Linha de corte",
                        backgroundColor: 'red',
                        borderColor: 'red',
                        borderWidth: 2,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                    }],
                },
                options: {
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: corFonteFraca
                            },
                        }
                    },

                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                },
                                filter: function (legendItem) {
                                    return legendItem.datasetIndex !== 1; // Oculta a legenda apenas para o conjunto de dados de índice 1 (linha de corte)
                                }
                            }
                        },
                        tooltip: {
                            callbacks: {
                                title: (tooltipItems) => {
                                    let nome = this.nomesDosProjetos[tooltipItems[0].dataIndex];
                                    return nome
                                },
                            },
                        },
                    },
                    onClick: (e) => {
                        const canvasPosition = getRelativePosition(e, canvas.chart);
                        const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);


                        this.nomeModal = this.nomesDosProjetos[dataX];
                        this.detalhesDeProjeto(this.listaDeProjetos[dataX]);


                    }
                },
            });
        },

        getPagarReceberAno() {

            this.mesPagarReceber = ""
            api.post('/indicador/contas/pagar-receber/ano', {
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosPagarReceber = response.data;

                    this.dadosFormatadosPagarReceber = this.dadosPagarReceber.map((item) => item.mes);
                    this.labelsPagarReceber = this.dadosPagarReceber.map((item) => item.mes);
                    this.labelsPagarReceber = this.labelsPagarReceber.map((numero) => this.nomesDosMesessemid[numero - 1])

                    this.dataGraficoPagar = this.dadosPagarReceber.map((item) => item.valorMesPagar);
                    this.dataGraficoReceber = this.dadosPagarReceber.map((item) => item.valorMesReceber);
                    let saldo = this.dataGraficoReceber.map((valor, indice) => valor - this.dataGraficoPagar[indice]);

                    this.datasetsPagarReceber = [];
                    this.datasetsPagarReceber.push({
                        data: saldo,
                        type: "line",
                        label: 'Saldo',
                        backgroundColor: '#E1BB22',
                        borderColor: '#E1BB22',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    }, {
                        data: this.dataGraficoReceber,
                        type: this.tipodegrafico,
                        label: 'Recebio',
                        backgroundColor: 'rgba(129, 199, 132, 1)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    }, {
                        data: this.dataGraficoPagar,
                        type: this.tipodegrafico,
                        label: 'Pago',
                        backgroundColor: '#E13417',
                        borderColor: '#E13417',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    })
                    this.renderChartPagarReceber()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        getPagarReceberMes() {
            // PUXA OS DADOS DO BACKEND PASSANDO MES E ANO
            this.mes = this.mesPagarReceber
            api.post('/indicador/contas/pagar-receber', {
                mes: this.mesPagarReceber,
                ano: this.ano,
            })
                .then((response) => {
                    this.dadosPagarReceber = response.data;
                    this.dadosPagarReceber.forEach((item) => {
                        item.semana = item.semana.toString().substring(4);
                    });

                    this.dadosFormatadosPagarReceber = this.dadosPagarReceber.map((item) => parseInt(item.semana, 10));

                    this.labelsPagarReceber = this.dadosFormatadosPagarReceber.map((value, index) => {
                        return `${index + 1}º semana`;
                    });
                    // * -1
                    this.dataGraficoPagar = this.dadosPagarReceber.map((item) => item.valorSemanaPagar);
                    this.dataGraficoReceber = this.dadosPagarReceber.map((item) => item.valorSemanaReceber);
                    let saldo = this.dataGraficoReceber.map((valor, indice) => valor - this.dataGraficoPagar[indice]);
                    //let saldo = this.dataGraficoPagar.map((valor, indice) => valor + this.dataGraficoReceber[indice]);

                    this.datasetsPagarReceber = [];
                    this.datasetsPagarReceber.push({
                        data: this.dataGraficoReceber,
                        type: this.tipodegrafico,
                        label: 'Recebio',
                        backgroundColor: 'rgba(129, 199, 132, 1)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    }, {
                        data: this.dataGraficoPagar,
                        type: this.tipodegrafico,
                        label: 'Pago',
                        backgroundColor: '#E13417',
                        borderColor: '#E13417',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    }, {
                        data: saldo,
                        type: "line",
                        label: 'Saldo',
                        backgroundColor: '#E1BB22',
                        borderColor: '#E1BB22',
                        borderWidth: 2,
                        tension: 0.3,
                        pointRadius: 2.2,
                        pointHoverRadius: 5,
                    },)

                    this.renderChartPagarReceber();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        mostrarDetalhePagarReceber() {
            this.detalhePagarReceber = [];
            api.post('/indicador/contas/semana-detalhe', {
                anoSemana: this.semanaPagoRecebidos,
            })
                .then((response) => {
                    this.listaPagarReceber = response.data;
                    this.classificarStatus('decrescente')
                })

                .catch((error) => {
                    console.error(error);
                });
            this.modalDetalhePagoeRecebido = !this.modalDetalhePagoeRecebido;
        },

        renderChartPagarReceber() {

            const canvas = document.getElementById('ChartPagarReceber');
            const ctx = canvas.getContext('2d');
            var corFonte = '';
            var corFonteFraca = '';

            if (this.temaEscuro == 'sim') {
                corFonte = 'rgba(255, 255, 255,.85)'
                corFonteFraca = 'rgba(255, 255, 255,.15)'
            } else {
                corFonte = 'rgba(0, 0, 0,.85)'
                corFonteFraca = 'rgba(0, 0, 0,.15)'
            }
            corFonteFraca

            if (canvas.chart) {
                canvas.chart.destroy();
            }

            canvas.chart = new Chart(ctx, {
                data: {
                    labels: this.labelsPagarReceber,
                    datasets: this.datasetsPagarReceber,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                        x: {
                            ticks: {
                                color: corFonte
                            }
                        },
                        y: {
                            ticks: {
                                color: corFonte
                            },
                            grid: {
                                color: (context) => (context.tick.value === 0 ? 'black' : 'rgba(0, 0, 0, 0.1)'),
                            },
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: 'nearest',
                    },
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                boxWidth: 15,
                                boxHeight: 15,
                                color: corFonte,
                                font: {
                                    size: 20,
                                    weight: 'bolder'
                                }
                            }
                        },
                    },
                    onClick: (e) => {
                        if (this.mesPagarReceber == "") {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);
                            this.mesPagarReceber = dataX + 1
                            this.getPagarReceberMes();
                        } else {
                            const canvasPosition = getRelativePosition(e, canvas.chart);
                            const dataX = canvas.chart.scales.x.getValueForPixel(canvasPosition.x);
                            this.dataXPagosRecebidos = dataX;

                            this.semanaPagoRecebidos = this.ano + this.dadosPagarReceber.map((item) => item.semana)[dataX];

                            this.mostrarDetalhePagarReceber()
                        }

                    }
                },
            });
        },

    }
}
</script>

<style scoped>
img {
    height: 36px;
    object-fit: cover;
}

canvas {
    max-width: 100%;
    max-height: 200px;
}
</style>