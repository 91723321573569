<template>
    <HeaderComponent v-if="!$route.meta.hideMenu"></HeaderComponent>
    <main id="main">
        <section>
            <router-view />
        </section>
    </main>
    <br>
</template>

<script>
import HeaderComponent from './assets/HeaderComponent.vue';

export default {
    components: {
        HeaderComponent: HeaderComponent,
    }
}

</script>

<style>
*::-webkit-scrollbar {
    max-width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--cor-separador);
    border-radius: 20px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: var(--cor-bg);
    border-radius: 8px;
    padding: 1rem;
    width: 90%;
    max-width: 1500px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}
</style>