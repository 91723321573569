import { createRouter, createWebHashHistory } from 'vue-router'
import Graficos from '../views/GraficosView.vue'
import { sso } from "roboflex-thalamus-sso-lib";


function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [

  {
    path: '/',
    name: 'Graficos',
    component: Graficos,
    beforeEnter: guardMyroute
  },

  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
